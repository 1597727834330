import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as userApi from "../../api/userApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   formControl: {
      minWidth: 400,
      padding: "5%",
   },
}));

const UserInvite = (props) => {
   let [email, setEmail] = useState();
   let [saving, setSaving] = useState(false);
   let [saveErrors, setSaveErrors] = useState(null);
   const history = useHistory();

   useEffect(() => {
      return () => setEmail("");
   }, [props]);

   function newUserFunc() {
      if (email === null) {
         setSaveErrors("Please Fill In Email.");
         return;
      }

      setSaving(true);

      userApi
         .inviteUser(email)
         .then((resp) => {
            if (!resp?.success) {
               setSaveErrors("Failed to send invite: " + resp.message);
            } else {
               props.openAlertMessage("Email Invite sent to user.", "success");
               props.handleClose();
               setSaving(false);
               if (resp?.id) {
                  history.push({ pathname: `/user/details/${resp.id}` });
               }
            }
         })
         .catch((err) => {
            setSaving(false);
            setSaveErrors("Failed to send invite: " + err);
         });
   }

   const classes = useStyles();

   return (
      <Dialog
         open={props.open}
         TransitionComponent={Transition}
         keepMounted
         onClose={props.handleClose}
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <Collapse in={saveErrors ? true : false}>
            <Alert
               style={{ color: "#FFFFFF" }}
               variant="filled"
               severity="error"
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setSaveErrors(null);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {saveErrors}
            </Alert>
         </Collapse>
         <DialogTitle id="alert-dialog-slide-title">New User</DialogTitle>
         <DialogContent className={classes.formControl}>
            <form>
               <FormControl>
                  <TextField
                     margin="dense"
                     id="standard-basic"
                     label="Email"
                     variant="standard"
                     name="email"
                     value={email}
                     onChange={(event) => setEmail(event.target.value)}
                     style={{ width: "18rem" }}
                  />
               </FormControl>
            </form>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose} variant="contained" color="inherit">
               Cancel
            </Button>
            <Button disabled={saving} onClick={newUserFunc} variant="contained" color="secondary">
               Send
            </Button>
         </DialogActions>
      </Dialog>
   );
};

UserInvite.propTypes = {
   open: PropTypes.bool.isRequired,
   user: PropTypes.object.isRequired,
   handleClose: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

UserInvite.defaultProps = {
   open: false,
   user: {},
   handleClose: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default UserInvite;
